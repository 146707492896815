import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { apiConstant } from 'src/environments/api.constant';

@Injectable({
  providedIn: 'root'
})
export class VersionUpdateService {

  constructor(private apiService: ApiService) { }
  
  getVersionInfo(): Observable<any> {
    return this.apiService.get(apiConstant.API_CHECK_APP_VERSION, {}).pipe(
      map((response: Response) => <any><unknown>response));
  }

  resetVersion(): Observable<any> {
    return this.apiService.post(apiConstant.API_RESET_APP_VERSION_INFO, {}).pipe(
        map((response: Response) => <any><unknown>response));
  }

}
