import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiConstant } from 'src/environments/api.constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  constructor(private _apiService: ApiService) { }
  
  changeUserPassword(userParams: any): Observable<any> {
    return this._apiService.post(apiConstant.API_CHANGE_PASSWORD, userParams);
  }

  getCurrentUserInfo(): Observable<any> {
    return this._apiService.get(apiConstant.API_GET_CURRRENT_USER_INFO, {}).pipe(map((response: Response) => <any><unknown>response));
  }
  
  updateCurrentUser(id: any, body: any): Observable<any> {
    return this._apiService.post(apiConstant.API_UPDATE_CURRRENT_USER, body).pipe(map((response: Response) => <any><unknown>response));
  }
  
  getAllowedCountriesCode(): Observable<any> {
    return this._apiService.get(apiConstant.API_GET_COUNTRY_LIST, {}).pipe(
        map((response: Response) => <any><unknown>response));
   }
  
}
