import { ApiConstant } from './api-constant.model';
import { build } from './build';

export const apiConstant: ApiConstant = {
  DEFAULT_LOGO: build.LOGO,
  DEFAULT_LANG: build.DEFAULT_LANG,
  API_LOGIN: build.API_HOST + 'loginToUbiVu',
  API_HOST: build.API_HOST,
  API_UPDATE_SERIAL_NUMBER: build.API_HOST + 'update-production-file',
  API_DELETE_SERIAL_NUMBER: build.API_HOST + 'delete-production-file',
  API_DOWNLOAD_CONFIG_FILE: build.API_HOST + 'download-config-file',
  API_CONFIG_FILE_LIST: build.API_HOST + 'get-config-list',
  API_PRODUCTION_FILE_HISTORY: build.API_HOST + 'get-production-file-list',
  API_USERS: build.API_HOST + 'getUsers',
  GET_ROLES: 'assets/mock-data/role.json',
  API_ADD_USER: build.API_HOST + 'add-user',
  API_UPDATE_USER: build.API_HOST + 'update-user',
  API_DELETE_USER: build.API_HOST + 'delete-user',
  API_GET_USER_INFO: build.API_HOST + 'get-user',
  API_CHANGE_PASSWORD: build.API_HOST + 'changepassword',
  API_GET_ACCOUNT_RECOVERY_MAIL: build.API_HOST + 'forgotpassword',
  API_RESET_PASSWORD: build.API_HOST + 'updatepassword',
  API_GET_CURRRENT_USER_INFO: build.API_HOST + 'get-me',
  API_UPDATE_CURRRENT_USER: build.API_HOST + 'update-me',
  API_USER_CHANGE_STATUS: build.API_HOST + 'changeUserCustomerStatus',
  API_VALIDATE_LINK: build.API_HOST + 'pw/check-reset-link-validity',
  API_GET_SERIAL_NUMBERS: build.API_HOST + 'get-serial-number-list',
  API_GET_COUNTRY_LIST: build.API_HOST + 'country-code-list',
  API_GET_SEARCH_TYPE: 'assets/mock-data/search-type.json',
  API_GET_CUSTOMER_ID: build.API_HOST + 'getallcustomers ',
  API_GET_PRODUCTION_FILE_BY_ID : build.API_HOST + 'get-production-file-by-id',
  API_UPDATE_OEM_TRANSFORMER_DETAILS: build.API_HOST + 'update-transformer-file',
  API_DELETE_OEM_TRANSFORMER_DETAILS: build.API_HOST + 'delete-transformer-file',
  API_GET_OEM_TRANSFORMER_LIST: build.API_HOST + 'get-transformer-file-list',
  API_GET_CHECK_UPLOADED_FILE_STATUS: build.API_HOST + 'check-uploaded-file-status',
  API_GET_CHECK_UPLOADED_PRODUCTION_FILE_STATUS: build.API_HOST + 'check-uploaded-production-file-status',
  API_GET_OEM_TRANSFORMER_PRODUCTION_FILE_BY_ID : build.API_HOST + 'get-transformer-file-by-id',
  API_GET_COIL_CALIBRATION_TRANSFORMER_LIST: build.API_HOST + 'get-production-file-list',
  API_UPDATE_COIL_CALIBRATION_TRANSFORMER_DETAILS: build.API_HOST + 'update-coil-file',
  API_DELETE_COIL_CALIBRATION_TRANSFORMER_DETAILS: build.API_HOST + 'delete-coil-file',
  API_GET_COIL_CALIBRATION_TRANSFORMER_PRODUCTION_FILE_BY_ID: build.API_HOST + 'get-coil-file-by-id',
  API_CHECK_COIL_CALIBRATION_UPLOADED_FILE_STATUS: build.API_HOST + 'check-uploaded-production-file-status',
  API_GET_SEARCH_TYPE_FIELDS: 'assets/mock-data/search-type-fields.json',
  API_CHECK_APP_VERSION: build.API_HOST + 'check-app-version',
  API_RESET_APP_VERSION_INFO: build.API_HOST + 'reset-app-version-info'
};

