import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ubi-cell-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {
  destroy = new Subject();
  showDialog = false;
  showNotice = false;

  constructor(
    public dialogRef: MatDialogRef<TimerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  rxjsTimer = timer(this.data["timerDuration"]*10, 1000);
  timer: number = this.data["timerDuration"] | 0;

  ngOnInit(): void {
    this.timer = this.data["timerDuration"];
    if(this.data["showTimer"])
    this.rxjsTimer.pipe(takeUntil(this.destroy)).subscribe(val => {
      this.timer--;

      if (this.timer === 0) {
        this.dialogRef.close(true);
        this.destroy.next();
        this.destroy.complete();
      }
   })
  }

}