import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  
  transform(items: any[], field: string, value: string): any[] {
    if (!items) {
        return [];
    }
    if (!field || !value) {
        return items;
    }
    return items.filter( it => {
        return it['customer_name_with_id'].toLowerCase().includes(value.toLowerCase());
    });
}


}
