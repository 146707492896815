
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { build } from './../../../../src/environments/build';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  version = build.VERSION;
  subVersion = build.SUB_VERSION;

  constructor(private _http: HttpClient) { }

  get(url: string, data): Observable<any> {
    return this._http.get(url, data);
  }

  post(url: string, data: any): Observable<any> {
    return this._http.post(url, data);
  }

  put(url: string, data: Object = {}): Observable<any> {
    return this._http.put(url, data);
  }

  delete(url: string): Observable<any> {
    return this._http.delete(url);
  }

  fileUpload(url: string, data: any): Observable<any> {
    return this._http.post(url, data);
  }
  public isLoggedInState: BehaviorSubject<string> = new BehaviorSubject<string>(
    'hide',
  );
  public isLogInProcess: BehaviorSubject<string> = new BehaviorSubject<string>(
    'show',
  );

  loadStaticJson(url: string): Observable<any> {
    return this._http.get(`${url}?v=${this.version}/${this.subVersion}`);
  }
}

