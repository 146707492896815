import { AuthService } from './auth/services/auth.service';
import { MyProfileComponent } from './profile/component/my-profile/my-profile.component';
import { ChangePasswordComponent } from './profile/component/change-password/change-password.component';
import { Component, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MENUS } from './page.menu';
import { StorageService } from 'src/app/shared/services/storage.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { permissionConstant } from 'src/environments/permission';
import { TimeDuration } from '../app.constant';
import { VersionUpdateService } from '../version-update.service';
import { TimerComponent } from '../shared/components/timer/timer.component';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnDestroy {
  adminMenu: string[] = permissionConstant.ADMIN_MENU;
  configMenu: string[] = permissionConstant.CONFIG_MENU;
  
  languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español (Spanish)' },
    { code: 'ch', name: '中文 (Chinese)' },
    { code: 'pt', name: 'Português (Portuguese)' },
  ];
  mobileQuery: MediaQueryList;
  fillerNav = MENUS;
  private _mobileQueryListener: () => void;
  private unsubscribe$ = new Subject<void>();
  private versionUpdateSub = new Subscription;
  private resetVersionUpdateSub = new Subscription;
  intervalForVersionCheck: any;

  constructor(changeDetectorRef: ChangeDetectorRef, 
      media: MediaMatcher, private storageService: StorageService, private _dialog: MatDialog, private _authService: AuthService
    , private _versionUpdateService: VersionUpdateService, private snackbar: MatSnackBar, private _translate: TranslateService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  logout() {
    this._authService.logout();
  }

  ngAfterViewInit(): void {
    this.checkAppVersion();
    this.intervalForVersionCheck = setInterval(() => { this.checkAppVersion() }, TimeDuration.periodicCallForCheckVersionUpdate);
  }

  customToogle(event,nav) {
    event.elementRef.nativeElement.classList.toggle('sidenavCollapsed');
    nav._elementRef.nativeElement.classList.toggle('collapsed')
  }

  changeLanguage(lang: string) {
    this.storageService.store('lang', lang);
    location.reload();
  }
  
  changePassword() {
    this._dialog.open(ChangePasswordComponent, { panelClass: 'change-pwd-dialog' });
  }
  
  viewProfile() {
    this._dialog.open(MyProfileComponent, { panelClass: 'my-profile-dialog' });
  }

  checkAppVersion() {
    let duration = TimeDuration.showUpdateAppVersionMsg;
    this.versionUpdateSub = this._versionUpdateService.getVersionInfo().subscribe(result => {
        if (result && result['status'] == "success" && result['data']['release_info']) {
          switch (result['data']['release_info']) {
            case 'authorization_logout':
              this.resetVersionStatus(result['data']['release_info'], duration);
              break;
            case 'security_logout':
              this.resetVersionStatus(result['data']['release_info']);
              break;
            case 'security':
              this.resetVersionStatus(result['data']['release_info']);
              break;
            case 'feature':
              this.snackbar.open(this._translate.instant('VERSION.VERSION_UPDATE_INFO'), '', {
                duration: duration,
                panelClass: ['version-update-info'],
              });
              setTimeout(() => {
                this.resetVersionStatus(result['data']['release_info']);
              }, duration);
              break;
            case 'feature_logout':
              this.snackbar.open(this._translate.instant('VERSION.VERSION_UPDATE_INFO'), '', {
                duration: duration,
                panelClass: ['version-update-info'],
              });
              setTimeout(() => {
                this.resetVersionStatus(result['data']['release_info']);
              }, duration);
              break;
            default:
              break;
          }
        }
    });
  }

  resetVersionStatus(releaseInfo, duration?: number) {
    this.resetVersionUpdateSub = this._versionUpdateService.resetVersion().pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (releaseInfo == 'authorization_logout') {
          this.openTimerDialog(duration / 1000);
          setTimeout(() => {
            this.logoutAndrefersh();
          }, duration);
         } else if (releaseInfo == 'feature_logout' || releaseInfo == 'security_logout') {
          this.logoutAndrefersh();
        } else if (releaseInfo == 'feature' || releaseInfo == 'security') {
          this.refersh();
        }
        this.snackbar.dismiss();
      })
  }

  openTimerDialog(duration = 10) {
    const dialogRef =   this._dialog.open(TimerComponent, {
        disableClose: true,
        data: {
          content: 'VERSION.PERMISSION_CHANGED_ALT_MSG',
          confirmActionText: 'VERSION.OKAY_LABLE',
          showTimer: true,
          timerDuration: duration // section
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.logoutAndrefersh();
        }
      });
  }

  logoutAndrefersh() {
    clearInterval(this.intervalForVersionCheck);
    this.logout();
    setTimeout(() => {
      this.refersh();
    });
  }

  refersh() {
    document.location.reload();
  }
  ngOnDestroy(): void {
    clearInterval(this.intervalForVersionCheck);
    if(this.versionUpdateSub){
      this.versionUpdateSub.unsubscribe();
    }
    if(this.resetVersionUpdateSub){
      this.resetVersionUpdateSub.unsubscribe();
    }
  }
}
