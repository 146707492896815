import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { UbviCalendarRangeModel } from '../ubivu-data-range-picker/model/ubivu-calendar-range.model';
import { UbviDateRangeComponent } from '../ubivu-data-range-picker/ubvi-date-range/ubvi-date-range.component';
import { finalize, takeUntil } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/page/configuration/services/configuration.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-file-history-filter',
  templateUrl: './file-history-filter.component.html',
  styleUrls: ['./file-history-filter.component.scss'],
})

export class FileHistoryFilterComponent implements OnInit, OnDestroy {
  searchValue: string;
  searchType: any = [];
  selectedSearchType: string = null;  
  start_date: NgbDate | null;
  formattedStartDate: string;
  formattedStartTime: string;
  private startTime = moment().startOf('day').format('HH:mm');
  end_date: NgbDate | null;
  formattedEndDate: string;
  formattedEndTime: string;
  private endTime = moment().endOf('day').format('HH:mm');
  private minDate: NgbDateStruct;
  private maxDate: NgbDateStruct;
  selectedStartDateTime: string = null;
  selectedEndDateTime: string = null
  private unsubscribe$ = new Subject<void>();
  @Output() submitFilter: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(
    private _configurationService: ConfigurationService,
    private formatter: NgbDateParserFormatter,
    public dialog: MatDialog ) { }

  ngOnInit() {
    const currentDate = new Date();
    const defaultDate = moment.utc(currentDate, 'YYYY-MM-DD');

    this.endTime = moment().endOf('day').format('HH:mm');
    this.start_date = new NgbDate(defaultDate.year(), defaultDate.month()+1, defaultDate.date());
    this.end_date = new NgbDate(defaultDate.year(), defaultDate.month()+1, defaultDate.date());

    const date = moment.utc(currentDate, 'YYYY-MM-DD').subtract(99, 'years');
    this.minDate = { year: date.year(), month: date.month() + 1, day: date.date() };
    const maxdate = moment.utc(currentDate, 'YYYY-MM-DD');
    this.maxDate = { year: maxdate.year(), month: maxdate.month() + 1, day: maxdate.date() }
    this.getSearchType();
  }

  getSearchType() {
    this._configurationService.getSearchTypeFields()
    .pipe(takeUntil(this.unsubscribe$), finalize(() => {
    })).subscribe((res) => {
      this.searchType = res['data'];
      this.selectedSearchType = res['data'][0]['key'];
    });
  }
  
  formateDateAndTime() {
    this.formattedStartDate = this.formateDateToString(this.start_date);
    this.formattedStartTime = this.formateTimeToString(this.startTime);
    this.selectedStartDateTime = `${this.formateDateToString(this.start_date)} ${this.formateTimeToString(this.startTime)}`;    
    this.formattedEndDate = this.formateDateToString(this.end_date);
    this.formattedEndTime = this.formateTimeToString(this.endTime);
    this.selectedEndDateTime = `${this.formateDateToString(this.end_date)} ${this.formateTimeToString(this.endTime)}`;
  }

  formateDateToString(date: NgbDate) {
    return moment(this.formatter.format(date)).format('YYYY-MM-DD');
  }

  formateTimeToString(time: string) {
    return moment(time, 'HH:mm').format('HH:mm');
  }

  onClickDateSelection(){
    const data: UbviCalendarRangeModel = {
      minDate: this.minDate,
      maxDate: this.maxDate,
      startDate: this.start_date,
      endDate: this.end_date,
      startTime: this.startTime,
      endTime: this.endTime,
      maxDaySelection: 30
    };

    const dialogRef = this.dialog.open(UbviDateRangeComponent, {
      width: '60vw',
      maxHeight: '96%',
      data: data
    });
    dialogRef.afterClosed().subscribe((result: UbviCalendarRangeModel) => {
      if (result) {
        this.start_date = result.startDate;
        this.end_date = result.endDate;
        this.startTime = result.startTime;
        this.endTime = result.endTime;        
        this.formateDateAndTime();
      }
    });
  }
 
  onFilterSubmitted() {
    let advFilterValue = {
      search_type: this.selectedSearchType ? this.selectedSearchType : null,
      search_by: this.searchValue ? this.searchValue : null,
      start_date_time: this.selectedStartDateTime,
      end_date_time: this.selectedEndDateTime,
    };
    this.submitFilter.emit(advFilterValue);
  }
  
  onFilterReset(){
    this.formattedStartDate = null;
    this.formattedEndDate = null;
    this.selectedSearchType = null;
    this.searchValue = null;
    this.selectedStartDateTime = null;
    this.selectedEndDateTime = null;
    this.selectedSearchType = this.searchType[0]['key'];
    const advFilterValue = {
      search_type: null,
      search_by: null,
      start_date_time: null,
      end_date_time: null,
    };
    this.submitFilter.emit(advFilterValue);
  }

  ngOnDestroy(): void{
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

