import { Directive, Input, ViewContainerRef, TemplateRef, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Directive({
  selector: '[hasAccessRights]',
})
export class AccessRightsDirective implements OnInit {

  // the role the user must have 
  @Input() hasAccessRights: string[];
  isVisible = false;

  /**
   * @param {ViewContainerRef} viewContainerRef 
   * 	-- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef 
   *   -- the templateRef to be potentially rendered
   */

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>, 
    private storageService: StorageService,
  ) {}
  
  ngOnInit() {
    
    const role = this.storageService.getStorageItem('user').usertype;
    
    if (!role) {
      this.viewContainerRef.clear();
    }
    
    if (this.hasAccessRights.indexOf(role) !== -1 ? true : false) {
      if (!this.isVisible) {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.isVisible = false;
      this.viewContainerRef.clear();
    }
  }

}