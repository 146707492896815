import { Directive, HostBinding, AfterViewInit, ElementRef, OnInit } from "@angular/core";

@Directive({
  selector: '[appTableScroll]',
})

export class TableScrollDirective implements AfterViewInit {
  divToMeasureWidth;
  tableToMeasureWidth;

  @HostBinding('class.h-scroll') isScrolled = false;
  constructor(private el: ElementRef) {
    
  }
  ngAfterViewInit(){
    setTimeout(() => {
      const parentElement = this.el.nativeElement;
      const tableElement = parentElement.querySelector('mat-table');
      this.divToMeasureWidth = parentElement.offsetWidth;
      this.tableToMeasureWidth = tableElement.offsetWidth;
      if (this.tableToMeasureWidth > this.divToMeasureWidth) {
        this.isScrolled = true;
      }
    }, 500);
  }

}