import { Injectable, OnDestroy } from '@angular/core';
import 'rxjs/add/operator/share';
import { Subject } from 'rxjs';

@Injectable()
export class StorageService implements OnDestroy {
  private onSubject = new Subject<{ key: string, value: any }>();
  public changes = this.onSubject.asObservable();

  constructor() {
    this.start();
  }

  ngOnDestroy() {
    this.stop();
  }

  public getStorage() {
    const s = [];
    for (let i = 0; i < localStorage.length; i++) {
      s.push({
        key: localStorage.key(i),
        value: localStorage.getItem(localStorage.key(i)),
      });
    }
    return s;
  }

  isJson(str) {
    try { JSON.parse(str); } catch (e) { return false; }
    return true;
  }

  public getStorageItem(key: string) {
    const item = localStorage.getItem(key) || null;
    return this.isJson(item) ? JSON.parse(item) : item;
  }

  public store(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
    this.onSubject.next({ key, value: data });
  }

  public clear(key) {
    localStorage.removeItem(key);
    this.onSubject.next({ key, value: null });
  }
  
  public clearAll() {
    localStorage.clear();
    this.onSubject.next();
  }
  
  public getAccessToken() {
    return this.getStorageItem('accessToken');
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage) {
      let v;
      try { v = JSON.parse(event.newValue); } catch (e) { v = event.newValue; }
      this.onSubject.next({ key: event.key, value: v });
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.onSubject.complete();
  }
}
