import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import decode from 'jwt-decode';
import { apiConstant } from 'src/environments/api.constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private _apiService: ApiService, private _router: Router) { }

  /**
   * this is used to clear anything that needs to be removed
   */
  clear(): void {
    localStorage.clear();
  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isAuthenticated(): boolean {
    return localStorage.getItem('accessToken') !== null && !this.isTokenExpired();
  }

  // simulate jwt token is valid
  isTokenExpired(): boolean {
    return false;
  }

  /**
   * this is used to clear local storage and also the route to login
   */
  logout(): void {
    this.clear();
    this._router.navigate(['/login']);
  }
  decode() {
    return decode(localStorage.getItem('accessToken'));
  }

  login(credentials: any): Observable<any> {
    return this._apiService.post(apiConstant.API_LOGIN, credentials);
  }
  
  getAccountRecoveryMail(params: any): Observable<any> {
    return this._apiService.post(apiConstant.API_GET_ACCOUNT_RECOVERY_MAIL, params);
  }
  
  validateLink(params: any) {
    return this._apiService.post(apiConstant.API_VALIDATE_LINK, params).pipe(
        map((response: Response) => <any><unknown>response));
  }
}
