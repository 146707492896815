import { emailPattern } from 'src/environments/regex.pattern';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { takeUntil, finalize } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ProfileService } from '../../service/profile.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit, OnDestroy {
  
  userForm: UntypedFormGroup;
  userRoles: any;
  private unsubscribe$ = new Subject<void>();
  allowCodeList = [];
  loadCountryFlag: boolean = false;
    
  constructor(public snackBar: MatSnackBar,
    private _fb: UntypedFormBuilder,
    private _profileService: ProfileService,
    private _apiService: ApiService,
    private _dialogRef: MatDialogRef<MyProfileComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.userForm = this.initUserForm();
  }
    
  ngOnInit() {
    this.getCountryList();
    this.fetchUserInfo();
  }
    
  initUserForm(): UntypedFormGroup {
    return this._fb.group(
      {
        firstname: [null, Validators.compose([Validators.required])],
        lastname: [null, Validators.compose([Validators.required])],
        email: [{ value: null, disabled: true }, Validators.compose([Validators.required, Validators.pattern(emailPattern)])],
        user_id: [{ value: null, disabled: true }], // hidden field 
        apiKey: ( { value: null, disabled: true } ),
        role_name: ( { value: null, disabled: true } ),
        phone: [null, Validators.compose([Validators.required])],
      });
  }
    
  fetchUserInfo() {
    this._profileService.getCurrentUserInfo().pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.userForm.patchValue(res['data']);
        this._apiService.isLoggedInState.next('hide');
      });         
  }
  
    /* To copy any Text */
copyText(val: string) {
  if (val !== null && val !== '') {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.showToast('Copied to clipboard');
  } else {
    this.showToast('Falid to copy', 'snackbar-error');
  }
}
  saveUser() {
    this.editConfirmation(this.data);
  }
    
  editConfirmation(userId) {
    const dialogData = new ConfirmDialogModel('ACTION_BUTTON.CONFIRM', 'MESSAGES.UPDATE_YOUR_PROFILE_CONFIRM_MSG');
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.editUser(userId);
      }
    });
  }
    
  editUser(userId) {
    this._apiService.isLoggedInState.next('show');
    const userParams = {
      firstname: this.userForm.controls['firstname'].value.trim(),
      lastname: this.userForm.controls['lastname'].value.trim(),
      id: this.userForm.controls['user_id'].value,
      phone: this.userForm.controls['phone'].value,
    };
    
    this._profileService.updateCurrentUser(userId, userParams).pipe(takeUntil(this.unsubscribe$))
    .subscribe(res => {
      this.showToast(res.message);
      this._dialogRef.close(true);
      this._apiService.isLoggedInState.next('hide');
    }, error => {
      this.showToast(error.error.message, 'snackbar-error');
    });
  } 
  
  showToast(msg, panelClass = 'snackbar-success') {
    this.snackBar.open(msg, 'Close', { duration: 5000, panelClass: [panelClass] });
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  
  getCountryList() {
  
    this._profileService.getAllowedCountriesCode().pipe(takeUntil(this.unsubscribe$),
        finalize(() => {
          this._apiService.isLoggedInState.next('hide');
        })).subscribe(
            async result => {
                this.allowCodeList = result['data'];
                 this.loadCountryFlag = true;
                 this._apiService.isLoggedInState.next('hide');
            });
     }
}
