import { Component, EventEmitter, Injectable, Input, Output, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDatepicker, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const I18N_VALUES = {
  en: {
    weekdays: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  },
  fr: {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  }
};

@Injectable() export class I18n { language = 'en'; }

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) { super(); }
  getWeekdayShortName(weekday: number): string { return I18N_VALUES[this._i18n.language].weekdays[weekday - 1]; }
  getWeekLabel(): string { return I18N_VALUES[this._i18n.language].weekLabel; }
  getMonthShortName(month: number): string { return I18N_VALUES[this._i18n.language].months[month - 1]; }
  getMonthFullName(month: number): string { return this.getMonthShortName(month); }
  getDayAriaLabel(date: NgbDateStruct): string { return `${date.day}-${date.month}-${date.year}`; }
}

@Component({
  selector: 'ubi-cell-ubvi-calendar-view',
  templateUrl: './ubvi-calendar-view.component.html',
  styleUrls: ['./ubvi-calendar-view.component.scss'],
  providers:
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})

export class UbviCalendarViewComponent {
  @Input()
  set startDate(startDate: NgbDate) {
    if(startDate){
      this._startDate = startDate;
      if(!this.fromDate || (this.startDate && this.startDate.equals(startDate))) {
        this._startDate = startDate;
        this.selectedDate =  startDate;
        this.onDateSelection(startDate);
        this.datePicker.navigateTo(startDate);
        this.datePicker.focus();
      }
    } else {
      this.selectedDate = null;
      this.hoveredDate = null;
      this.fromDate = null;
      this.toDate = null;
    }
  }

  get startDate() { return this._startDate; }

  @Input()
  set endDate(endDate: NgbDate) {
    if(endDate){
      this._endDate = endDate;
      if(this.fromDate && !this.toDate || (this.toDate && this.startDate.equals(endDate))){
        this.onDateSelection(endDate);
      }  
    }
  }

  get endDate() { return this._endDate; } 

  @Input() displayMonths;
  @Input() minDate: NgbDate;
  @Input() maxDate: NgbDate;
  @Input() color;
  @Input() maxDaySelection = 0;
  @Output() selectedStartDate = new EventEmitter<NgbDate>();
  @Output() selectedEndDate = new EventEmitter<NgbDate>();
 
  private _startDate: NgbDate;
  private _endDate: NgbDate;

  hoveredDate: NgbDate;
  markDisabled: (date: NgbDate, current: { month: number; }) => boolean;

  fromDate: NgbDate;
  toDate: NgbDate;
  selectedDate: NgbDate;
  @ViewChild(NgbDatepicker, { static: true }) datePicker: NgbDatepicker;

  constructor(private calendar: NgbCalendar) {}

  onDateSelection(date: NgbDate) { 
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      let firstDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      let secondDate = new Date(date.year, date.month - 1, date.day);
      let Time = secondDate.getTime() - firstDate.getTime();
      let diffInDays = Time / (1000 * 3600 * 24); //Difference in Days
      if(diffInDays >= 30){
        this.toDate = this.calendar.getNext(this.fromDate, 'd', this.maxDaySelection-1);
      } else {
        this.toDate = date;
      }
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.selectedStartDate.emit(this.fromDate);
    if (this.toDate) {
      this.selectedEndDate.emit(this.toDate);
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  isfuture(date: NgbDate) {
    return date.after(this.maxDate);
  }

  isPast(date: NgbDate) {
    return date.before(this.minDate);
  }

  onClickPreviousMonth() {
    if (this.selectedDate && (this.selectedDate.after(this.minDate) || this.selectedDate.equals(this.minDate))) {
      this.selectedDate = this.calendar.getPrev(this.selectedDate, 'm', 1);
      const { state, calendar } = this.datePicker;
      this.datePicker.navigateTo(calendar.getPrev(state.firstDate, 'm', 1));
      this.datePicker.focus();
    }

    if(!this.selectedDate) {
      const { state, calendar } = this.datePicker;
      this.datePicker.navigateTo(calendar.getPrev(state.firstDate, 'm', 1));
      this.datePicker.focus();
    }
  }

  onClickNextMonth() {
    if (this.selectedDate && (this.selectedDate.before(this.maxDate) || this.selectedDate.equals(this.maxDate))) {
      this.selectedDate = this.calendar.getNext(this.selectedDate, 'm', 1);
      const { state, calendar } = this.datePicker;
      this.datePicker.navigateTo(calendar.getNext(state.firstDate, 'm', 1));
      this.datePicker.focus();
    }
 
    if(!this.selectedDate) {
      const { state, calendar } = this.datePicker;
      this.datePicker.navigateTo(calendar.getNext(state.firstDate, 'm', 1));
      this.datePicker.focus();
    }
  }
}
