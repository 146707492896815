import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { finalize, map, catchError } from 'rxjs/operators';
import { apiConstant } from 'src/environments/api.constant';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class ValidateLinkGuard implements CanActivate {
  private httpClient: HttpClient;

  constructor(
  private _router: Router,
  private _handler: HttpBackend,
  private _snackBar: MatSnackBar) {
    this.httpClient = new HttpClient(this._handler);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean  {

    return this.httpClient.post(apiConstant.API_VALIDATE_LINK , state['_root']['value']['queryParams'])
    .pipe(finalize(() => {
    }),
      map(res => {
        if (res['data']['is_valid']) {
          return true;
        } else {
          this.showToast(res['message'], 'snackbar-error');
          this._router.navigate(['/login']);
          return false;
        }
      }),
      catchError((err) => {
        this.showToast(err.error.message, 'snackbar-error');
        this._router.navigate(['/login']);
        return of(false);
      }),
    );
  }

  private showToast(msg, panelClass) {
    this._snackBar.open(msg, 'Close', { duration: 5000, panelClass: [panelClass] });
  }
}
