import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageRoutingModule } from './page-routing.module';
import { PageComponent } from './page.component';
import { MaterialModule } from '../shared/material.module';
import { ProfileModule } from './profile/profile.module';


@NgModule({
  declarations: [PageComponent],
  imports: [
    CommonModule,
    PageRoutingModule,
    SharedModule.forRoot(),
    MaterialModule,
    ProfileModule,
  ],
})
export class PageModule { }
