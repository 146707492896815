import { Injectable } from '@angular/core';
import CryptoJS  from 'crypto-js';
import { Observable, of } from 'rxjs';
import { build } from 'src/environments/build';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {

  encryptRequestParams(params: any): Observable<any> {
    const secret_key = CryptoJS.enc.Hex.parse(`${build.SECRET_KEY}`);
    const secret_iv = CryptoJS.enc.Hex.parse(`${build.SECRET_IV}`);
    const encryptedString = CryptoJS.AES.encrypt(JSON.stringify(params),
      secret_key,
      {
        iv: secret_iv,
        padding: CryptoJS.pad.ZeroPadding,
      }).toString();

    return of(encryptedString.trim());
  }

  decrypteRequestParams(params): Observable<any> {
    const secret_key = CryptoJS.enc.Hex.parse(`${build.SECRET_KEY}`);
    const secret_iv = CryptoJS.enc.Hex.parse(`${build.SECRET_IV}`);

    const decryptedString = CryptoJS.AES.decrypt(params,
          secret_key,
          {
            iv: secret_iv,
            padding: CryptoJS.pad.Pkcs7,
          }).toString(CryptoJS.enc.Utf8);
    return of(JSON.parse(decryptedString));
  }
}
