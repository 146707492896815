import { Component, OnInit, Inject } from '@angular/core';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UbviCalendarRangeModel } from '../model/ubivu-calendar-range.model';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import * as moment from 'moment';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';

@Component({
  selector: 'ubi-cell-ubvi-date-range',
  templateUrl: './ubvi-date-range.component.html',
  styleUrls: ['./ubvi-date-range.component.scss']
})

export class UbviDateRangeComponent implements OnInit {
  formDateChanged: boolean = false;
  isSingleDateSelection : boolean = false;
  calendarRangeModel: UbviCalendarRangeModel;
  maxDateSelection :number = 0;
  positionOptions: TooltipPosition = 'above';
  allDaySelected : boolean = true;
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: UbviCalendarRangeModel, private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UbviDateRangeComponent>, public formatter: NgbDateParserFormatter,
    private translateService: TranslateService) {
    this.calendarRangeModel = dialogData;
  }

  ngOnInit(): void {
    if(this.allDaySelected) {
      this.calendarRangeModel.startTime = moment().startOf('day').format('HH:mm');
      this.calendarRangeModel.endTime = moment().endOf('day').format('HH:mm');
    }
  }

  onChangeStartTime($event: string) {
    this.calendarRangeModel.startTime = $event;
  }

  onChangeEndTime($event: string) {
    this.calendarRangeModel.endTime = $event;
  }

  changePeriod(event: MatSelectChange) {
    this.formDateChanged = true;
  }

  onChangeStartDate($event: NgbDate) {
    if (!this.calendarRangeModel.startDate.equals($event)) {
      this.formDateChanged = true;      
    }
    this.calendarRangeModel.startDate = $event;
  }

  onChangeEndDate($event: NgbDate) {
    this.calendarRangeModel.endDate = $event;
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  onClickApply() {
    const startDate = moment(this.formatter.format(this.calendarRangeModel.startDate) + " " + this.calendarRangeModel.startTime, 'YYYY-MM-DD HH:mm');
    const endDate = moment(this.formatter.format(this.calendarRangeModel.endDate) + " " + this.calendarRangeModel.endTime, 'YYYY-MM-DD HH:mm');
    if (!startDate.isBefore(endDate)) {
      this.showToast(this.translateService.instant('VALIDATION.START_DATE_END_DATE_VALICATION'));
    } else {
      this.dialogRef.close(this.calendarRangeModel);
    }
  }

  showToast(msg, panelClass = 'snackbar-success') {
    this.snackBar.open(msg, 'Close', {
      duration: 5000,
      panelClass: [panelClass],
    });
  }

  allDayToggle(event) {
    this.allDaySelected = event.checked;
    this.formDateChanged = true;
    if(this.allDaySelected) {
      this.calendarRangeModel.startTime = moment().startOf('day').format('HH:mm');
      this.calendarRangeModel.endTime = moment().endOf('day').format('HH:mm');
    } else {
      this.calendarRangeModel.startTime = moment().startOf('day').format('HH:mm');
      this.calendarRangeModel.endTime = moment().format('HH:mm');
    }
  }
}
