export class PaginatorConfig {
  self?: number = 20;
  configfile?: number = 20;
  productionHistory?: number = 20;
  userlist?: number = 20;
  serialNumberlist?: number = 20;

  constructor(
  {
    self= 20,
    configfile = 20,
    productionHistory = 20,
    userlist = 20,
    serialNumberlist = 20,
  }) {
    this.self = self;
    this.configfile = configfile;
    this.productionHistory = productionHistory;
    this.userlist = userlist;
    this.serialNumberlist = serialNumberlist;
  }

  setValue(key, value) {
    switch (key) {
      case 'configfile':
        this.configfile = value;
        break;
      
      case 'productionHistory':
        this.productionHistory = value;
        break;
      case 'userlist':
        this.userlist = value;
        break;
      case 'serialNumberlist':
        this.serialNumberlist = value;
        break;  
        
      default:
        this.self = value;
        break;
    }
  }
}
