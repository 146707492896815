export const permissionConstant = {
  SYSTEM_USER: ['configuser' , 'factoryuser', 'factoryadmin'],
  CONFIG_MENU: ['configuser' , 'factoryuser', 'factoryadmin'], // configuration menu and pages/configuration
  ADMIN_MENU: ['configuser'], // admin menu and pages/admin
  PRODUCTION_FILE_TAB: ['configuser', 'factoryuser', 'factoryadmin'],
  CONFIGURATION_FILE_TAB: ['configuser', 'factoryuser', 'factoryadmin'],
  PRODUCTION_FILE_UPLOAD_SECTION: ['factoryadmin', 'configuser', 'factoryuser'],
  PRODUCTION_FILE_LIST_SECTION: ['configuser', 'factoryuser', 'factoryadmin'],
  PRODUCTION_FILE_SAVE_BUTTON: ['factoryadmin', 'configuser', 'factoryuser'],
  PRODUCTION_FILE_DELETE_BUTTON: ['configuser','factoryadmin'], 
  CONFIGURATION_FILE_LIST_SECTION: ['configuser', 'factoryuser', 'factoryadmin'],
  USERS_TAB: ['configuser'],
  ADD_USER_BUTTON: ['configuser'],
  USERS_LIST_SECTION: ['configuser'],
  SERIAL_NUMBERS: ['configuser'],
  OEM_PRODUCTION_FILE_TAB: ['configuser', 'factoryuser', 'factoryadmin'],
  OEM_PRODUCTION_FILE_UPLOAD_SECTION: ['configuser', 'factoryuser', 'factoryadmin'],
  OEM_PRODUCTION_FILE_LIST_SECTION: ['configuser', 'factoryuser', 'factoryadmin'],
  OEM_PRODUCTION_FILE_SAVE_BUTTON: [ 'configuser', 'factoryuser', 'factoryadmin'],
  OEM_PRODUCTION_FILE_DELETE_BUTTON: ['configuser','factoryadmin'],
  COIL_CALIBRATION_PRODUCTION_FILE_TAB: ['configuser', 'factoryuser', 'factoryadmin'],
  COIL_CALIBRATION_PRODUCTION_FILE_UPLOAD_SECTION:  ['configuser', 'factoryuser', 'factoryadmin'],
  COIL_CALIBRATION_PRODUCTION_FILE_LIST_SECTION:  ['configuser', 'factoryuser', 'factoryadmin'],
  COIL_CALIBRATION_PRODUCTION_FILE_SAVE_BUTTON:  [ 'configuser', 'factoryuser', 'factoryadmin'],
  COIL_CALIBRATION_PRODUCTION_FILE_DELETE_BUTTON:  ['configuser','factoryadmin'],
};
