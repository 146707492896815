import { PageModule } from './page/page.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppComponent } from './app.component';
import { SingletonModule } from './shared/singleton.module';
import { routing } from './app-routing.module';
import { EncryptionService } from './encryption.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    routing,
    PageModule,
    SingletonModule,
  ],
  bootstrap: [AppComponent],
  providers: [EncryptionService]
})
export class AppModule { }
