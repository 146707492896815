import { NgbDate, NgbDateStruct, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";

export class UbviCalendarRangeModel {
    startDate: NgbDate;
    endDate: NgbDate;
    minDate: NgbDateStruct;
    maxDate: NgbDateStruct;
    startTime: string;
    endTime: string;
    maxDaySelection: number;
}
