import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
})

export class BottomSheetComponent implements OnInit {
  items: string[] = [];

  constructor(
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
      private bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    ) { }

  ngOnInit() {
    this.items = this.data.sort();
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }
}

